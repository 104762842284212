import Vue from 'vue';
import { uniqBy } from 'lodash-es';
import { MediaObjectPosition } from '@/node_modules/@osp/design-system/types/media';
import { Category } from '@/node_modules/@osp/design-system/types/category';
import CategoryList from '@/node_modules/@osp/design-system/components/CategoryList/CategoryList.vue';
import { CategoryListModel } from '@/node_modules/@osp/design-system/components/CategoryList/CategoryList.props';
import { DYCategoryListProps } from './dy-category-list.props';
import { mapImage } from '~/assets/js/mapper/image';
import { getAffinityData } from '~/assets/js/dynamic-yield/affinities';
import { url } from '~/@api/backend';
import { useSearchStore } from '~/@api/store/searchApi';

// Constant(s) -------------------------------------------------------------------------------------

const MAX_CATEGORIES = 6;

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'DyCategoryList',
	components: { CategoryList },
	props: DYCategoryListProps,
	data() {
		return {
			categories: null,
			loaded: false,
		};
	},
	computed: {
		title(): string {
			return this.$t(this.$i18nKeys.dyCategoryList.title[this.categories ? 'affinity' : 'normal']);
		},
		categoryListData(): CategoryListModel {
			return {
				uid: this.uid,
				categories: this.visibleCategories,
			};
		},
		visibleCategories(): Category[] {
			// If there are less than MAX_CATEGORIES provided by DY, fill the visibleCategories with fallbackCategories
			return [...(this.categories || []), ...this.fallbackCategories].slice(0, MAX_CATEGORIES);
		},
	},
	mounted() {
		this.loadAffinityCategories().then(() => (this.loaded = true));
	},
	methods: {
		async loadAffinityCategories(): Promise<void> {
			const affinity = await getAffinityData(this.$store, 'categories');
			const categoryNames = Object.keys(affinity || {});

			if (!categoryNames.length) return;

			const categories = uniqBy(
				Object.values(useSearchStore(this.$store).state.categories),
				'name',
			);

			this.categories = categories
				.filter(({ name }) => categoryNames.includes(name))
				.sort((a, b) => affinity[b.name] - affinity[a.name])
				.slice(0, MAX_CATEGORIES) // We do not need to map more categories than we show
				.map((category): Category => {
					return {
						code: category.code,
						name: category.name,
						url: url(this.$store, `--${category.code}-c.html`),
						image: mapImage(
							this.$store,
							category.image,
							{},
							{ preset: 'v3-product-tile', width: 150, path: 'product/tile' },
						),
						imageObjectPosition: MediaObjectPosition[category.image.imageObjectPosition],
					};
				});
		},
	},
});
