import { render, staticRenderFns } from "./CategoryList.vue?vue&type=template&id=8be18c8c&"
import script from "./CategoryList.ts?vue&type=script&lang=ts&"
export * from "./CategoryList.ts?vue&type=script&lang=ts&"
import style0 from "./CategoryList.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CategoryListItem: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/CategoryList/partials/CategoryList__Item.vue').default,SliderItem: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Slider/partials/Slider__Item.vue').default,Slider: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Slider/Slider.vue').default})
