import { render, staticRenderFns } from "./dy-category-list.vue?vue&type=template&id=c9255dd0&"
import script from "./dy-category-list.ts?vue&type=script&lang=ts&"
export * from "./dy-category-list.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionTitle: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/SectionTitle/SectionTitle.vue').default,CategoryList: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/CategoryList/CategoryList.vue').default})
