import type { Store } from 'vuex';
import { yieldToMain } from '@/node_modules/@osp/design-system/assets/js/utilities/runTask';
import { RootState } from '~/@api/store.types';
import { isDYEnabledForPage } from '~/tracking/trackingutils';

export interface Affinity {
	[name: string]: number;
}

interface Affinities {
	[affinity: string]: Affinity;
}

async function getDYAPI(store: Store<RootState>, subApiName?: string, timeout = 5000, wait = 10) {
	if (isDYEnabledForPage(store) && timeout > 0) {
		await yieldToMain();

		const dyAPI = typeof window === 'undefined' ? undefined : (window as any).DY;

		if (dyAPI) {
			if (subApiName) {
				if (dyAPI[subApiName]) {
					return dyAPI[subApiName];
				}
			} else {
				return dyAPI;
			}
		}

		await new Promise((resolve) => setTimeout(resolve, wait));

		return getDYAPI(store, subApiName, timeout - wait, wait);
	}

	return null;
}

export async function getAffinityData(
	store: Store<RootState>,
	affinity: string,
): Promise<Affinity> {
	await yieldToMain();

	const dyServerUtil = await getDYAPI(store, 'ServerUtil');

	if (!dyServerUtil) {
		return null;
	}

	return new Promise((resolve, reject) =>
		dyServerUtil.getUserAffinities(function (error, data: Affinities) {
			if (error) {
				reject(error);
			} else {
				resolve(data?.[affinity] || null);
			}
		}),
	);
}
