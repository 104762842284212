import { PropType } from 'vue';
import { Props } from '../../types/core';
import { Category } from '../../types/category';

// Model -------------------------------------------------------------------------------------------

export interface CategoryListModel {
	uid: string;
	categories: Category[];
}

// Props -------------------------------------------------------------------------------------------

export const CategoryListProps: Props<CategoryListModel> = {
	uid: {
		type: String as PropType<CategoryListModel['uid']>,
		required: true,
	},
	categories: {
		type: Array as PropType<CategoryListModel['categories']>,
		required: true,
	},
};
