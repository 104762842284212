import Vue from 'vue';
import { SwiperOptions } from 'swiper';
import { TriggerTheme } from '../../types/trigger';
import { CategoryListProps } from './CategoryList.props';
import CategoryListItem from './partials/CategoryList__Item.vue';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'CategoryList',
	components: { CategoryListItem },
	props: CategoryListProps,
	data() {
		return {
			triggerTheme: TriggerTheme.CTA,
			swiperOptions: {
				slidesPerView: 'auto',
				slidesPerGroup: 2,
			} as SwiperOptions,
		};
	},
});
