












import Vue, { PropType } from 'vue';
import { Media, MediaObjectPosition } from '../../../types/media';

export default Vue.extend({
	props: {
		name: { type: String, required: true },
		url: { type: String, required: true },
		image: { type: Object as PropType<Media>, required: true },
		imageObjectPosition: {
			type: String as PropType<MediaObjectPosition>,
			default: MediaObjectPosition.CENTER,
		},
	},
});
