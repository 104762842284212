import { PropType } from 'vue';
import { Props } from '@/node_modules/@osp/design-system/types/core';
import { TrackingEvents } from '@/node_modules/@osp/design-system/types/tracking';
import { Category } from '~/../design-system/types/category';

// Model -------------------------------------------------------------------------------------------

export interface DYCategoryListModel {
	uid: string;
	fallbackCategories: Category[];
	tracking?: TrackingEvents;
}

// Props -------------------------------------------------------------------------------------------

export const DYCategoryListProps: Props<DYCategoryListModel> = {
	uid: {
		type: String as PropType<DYCategoryListModel['uid']>,
		required: true,
	},
	fallbackCategories: {
		type: Array as PropType<DYCategoryListModel['fallbackCategories']>,
		required: true,
	},
	tracking: {
		type: Object as PropType<DYCategoryListModel['tracking']>,
	},
};
